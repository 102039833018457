import { useIsNewPlatform } from "./useIsNewPlatform";

export const useRedirectToInvestorArea = () => {
  const isNewPlatform = useIsNewPlatform();

  return () => {
    if (isNewPlatform()) {
      window.location.href = "/painel";
    } else {
      window.location.href = "/account/investments";
    }
  };
};
